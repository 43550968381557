<template>
  <b-card no-body>
    <b-card-header>
      <h5>{{ $t("generic.filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          {{ $t('generic.search') }}
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="query.search"
              debounce="1000"
              :placeholder="$t('generic.search')"
            />
          </b-input-group>
        </b-col>
        <b-col
          md="4"
          class="mb-1"
        >
          {{ $t('generic.country') }}
          <v-select
            id="club-country-id"
            v-model="query.idCountry"
            class="select2-secondary"
            label="name"
            :options="countries"
            :reduce="country => country.id"
            :placeholder="$t('generic.selectCountry')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          md="4"
          class="mb-1"
        >
          {{ $t('generic.state') }}
          <v-select
            id="club-state-id"
            v-model="query.idState"
            label="name"
            :options="states"
            :disabled="query.idCountry == null"
            :reduce="state => state.id"
            :placeholder="$t('generic.selectState')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          md="4"
          class="mb-1"
        >
          {{ $t("generic.status") }}
          <v-select
            v-model="query.status"
            label="text"
            :placeholder="$t('generic.selectStatus')"
            :options="options"
            :reduce="item => item.value"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import countryService from '@/services/countryService'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      query: {
        idCountry: null,
        idState: null,
        status: null,
        search: null,
      },
      countriesList: [],
      statesList: [],
    }
  },

  computed: {
    countries() {
      const list = this.countriesList.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },

    states() {
      const list = this.statesList.map(c => ({ id: c.hash, name: c.name }))
      return list
    },

    options() {
      return [
        { value: '1', text: `${this.$t('generic.active')}` },
        { value: '0', text: `${this.$t('generic.inactive')}` },
      ]
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.fetchCountries()
    },
    'query.idCountry': function queryIdCountry(pCountry) {
      (pCountry !== null) ? this.fetchStates(pCountry) : this.states = []

      this.query.idState = null
      this.$emit('filter-query', this.query)
    },
    'query.idState': function queryIdState() {
      this.$emit('filter-query', this.query)
    },
    'query.status': function queryStatus() {
      this.$emit('filter-query', this.query)
    },
    'query.search': function querySearch() {
      this.$emit('filter-query', this.query)
    },
  },
  mounted() {
    this.fetchCountries()
  },

  methods: {
    fetchCountries() {
      countryService.getCountries().then(({ data }) => {
        this.countriesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchStates(id) {
      const filtersObj = { country_hash: id }

      countryService.getStates(filtersObj).then(({ data }) => {
        this.statesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>
